<template>
  <div>
    <!-- Title -->
    <div class="mevcut-form-title mb-5">
      {{ t('change_item', { name: t('password') }) }}
    </div>

    <!-- Phone -->
    <AuthTextField
      v-model="form.phone"
      :label="t('phone')"
      error="phone_reset-phone"
      readonly
    />

    <!-- Code -->
    <AuthTextField
      v-model="form.code"
      :label="t('sms_code')"
      placeholder="- - - - - -"
      error="phone_reset-code"
    />

    <!-- Password -->
    <AuthTextField
      v-model="form.password"
      :label="t('password')"
      placeholder="********"
      type="password"
      error="phone_reset-password"
    />

    <!-- Password Confirmation -->
    <AuthTextField
      v-model="form.passwordConfirmation"
      :label="t('password_confirmation')"
      placeholder="********"
      type="password"
      error="phone_reset-password_confirmation"
    />

    <!-- Submit -->
    <MevcutResponseError
      class="my-2"
      api-key="phone_reset"
      :except="[
        'phone_reset-phone',
        'phone_reset-code',
        'phone_reset-password',
        'phone_reset-password_confirmation'
      ]"
    />
    <div v-if="form.remain > 0" class="text-red-500 text-sm my-2">
      {{ t('try_after_seconds', { seconds: form.remain }) }}
    </div>
    <MevcutSubmitButton
      class="my-2"
      :loading="form.loading"
      :disabled="form.remain > 0"
      @submit="resetHandler"
    />

    <!-- Retry After -->
    <div class="flex items-center justify-between">
      <button
        type="button"
        class="my-2 text-sm"
        :disabled="form.remain > 0"
        :class="[form.remain > 0 ? 'text-gray-500' : 'text-nav_color']"
        @click.prevent="resendHandler"
      >
        {{ t('resend_item', { name: t('sms_code') }) }}
      </button>
    </div>
  </div>
</template>

<script setup lang="ts">
definePageMeta({ auth: false })

const { form, resendHandler, resetHandler, clear } = useResetByPhone()
const { url } = useDomainHost()
const { t } = useI18n()

onBeforeUnmount(() => clear())

useServerSeoMeta(
  useOgMeta(
    url + '/auth/phone-reset-password',
    t('password_reset'),
    t('mevcut_description'),
    url + '/images/mevcut.webp'
  )
)

useHead({
  title: t('password_reset'),
  link: [useSeoCanonical(url + '/auth/phone-reset-password')],
  script: [
    useSeoBreadcrumb([
      useSeoBreadcrumbItem(1, t('home'), url),
      useSeoBreadcrumbItem(
        2,
        t('password_reset'),
        url + '/auth/phone-reset-password'
      )
    ])
  ]
})
</script>
